<template>
  <div class="main_profile_wrapper w-100 px-5">
    <div class="container-fluid">
      <div class="row d-block">
        <h2 class="font-weight-bold">Build a new team</h2>
        <p>Add members to start seeing what each team member brings to the team, and what they need from a team, and enjoy many of the team-specific features mentioned below.</p>
      </div>
      <div class="row mb-2">
        <div class="col-md-6 p-0 mb-2 px-2">
          <div class="border rounded-lg p-4 shadow-sm" style="min-height: 208px; background: #fff;">
            <img src="../../../assets/new-theme/plus.png" alt="" class="">
            <div class="pt-3">
              <h5 class="m-0">Add projects and tasks to that team</h5>
              <p class="text-muted">Enter your project's description and see how your team can work together using everyone's Learning Processes.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 p-0 mb-2 px-2">
          <div class="border rounded-lg p-4 shadow-sm" style="min-height: 208px; background: #fff;">
            <img src="../../../assets/new-theme/transfer.png" alt="" class="">
            <div class="pt-3">
              <h5 class="m-0">Team interaction</h5>
              <p class="text-muted">Share with your teammates what you will bring to the team and what you need from the team for each Learning Pattern</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 p-0 mb-2 px-2">
          <div class="border rounded-lg p-4 shadow-sm" style="min-height: 208px; background: #fff;">
            <img src="../../../assets/new-theme/elements.png" alt="" class="">
            <div class="pt-3">
              <h5 class="m-0">Similarities and differences</h5>
              <p class="text-muted">This will help all of you see the similarities and differences in your team uses their Learning Patterns.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 p-0 mb-2 px-2">
          <div class="border rounded-lg p-4 shadow-sm" style="min-height: 208px; background: #fff;">
            <img src="../../../assets/new-theme/tv.png" alt="" class="">
            <div class="pt-3">
              <h5 class="m-0">Team's LCI scores</h5>
              <p class="text-muted">Once they accept the invitation and complete the LCI, you will see the team's LCI scores both as a table and as a graph.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          
        </div>
      </div>
    </div>
    <div class="main_table">
      <TableHeaderSection
        @toggleModal="ToggleTaskModal('add')"
        title="Your teams"
        :badge="allTeamsList.length +' teams'"
        text="Manage and edit your teams or build a new ones."
        buttonText="Build a new team"
        joinbuttonText="Join a team"
        :total="allTeamsList.length"
      ></TableHeaderSection>
      <div>
        <CustomTable
          :count="allTeamsList.length"
          :DataItems="allTeamsList"
          @Refresh="refresh"
          @reset="filter = {}"
          :filter="filter"
          :title="'teams'"
          hidden_export_import
          admin_permission="admin_teams"
          edit_permission="add_teams"
          @selection="selections"
        >
          <template #columns>
            <el-table-column prop="name" sortable :label="`${localization('Team Name')}`"></el-table-column>
            <el-table-column  sortable :label="`${localization('Team Status')}`"></el-table-column>
            <el-table-column
              prop="members"
              sortable
              :label="`${localization('Number of members')}`"
            >
              <template #default="{ row }">{{ row.members.length }} members</template>
            </el-table-column>
            <el-table-column  sortable :label="`${localization('Your role')}`">
              <!-- <template #default="{ row }">{{ row.members.length }} members</template> -->
            </el-table-column>
            <el-table-column prop="" label="_" width="100">
              <template slot-scope="scope">
                <div class="td-actions">
                  <router-link
                    :to="`/new-theme/teams/${scope.row.id}`"
                    v-tooltip.top-center="`${localization('Show Team')}`"
                    class="btn btn-info btn-link btn-xs"
                  >
                    <i class="fa fa-eye text-dark"></i
                  ></router-link>
                  
                </div>
              </template>
            </el-table-column>
          </template>
        </CustomTable>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from "src/components/index";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "CareerView",

  data() {
    return {
      filter: {}
    };
  },
  computed: {
    ...mapGetters({
      loadingValue: ["user/usersLoad"],
      contactList: ["contacts/contactList"],
      allTeamsList: ["user/get_teams"],
      userStatements: ["attributes/get_user_statements"],
      team_cards_content: ["user/get_team_cards_content"],
      team_pages_content: ["user/get_team_pages_content"],
      institutionUsersList: ["groups/getInstUsers"]
    })
  },
  methods: {
    refresh(query) {
      this.$store.dispatch("user/get_all_teams");
      this.$store.dispatch("user/get_all_teams_content");
      this.$store.dispatch("attributes/GetTeamStatements", { query: query });
      this.$store
        .dispatch("attributes/getUserStatements", { query: query })
        .then(_ => {
          console.log(
            " loadingValue >>> ",
            this.loadingValue,
            "\n",
            " contactList >>> ",
            this.contactList,
            "\n",
            " allTeamsList >>> ",
            this.allTeamsList,
            "\n",
            " userStatements >>> ",
            this.userStatements,
            "\n",
            " team_cards_content >>> ",
            this.team_cards_content,
            "\n",
            " team_pages_content >>> ",
            this.team_pages_content,
            "\n",
            " institutionUsersList >>> ",
            this.institutionUsersList,
            "\n"
          );
        });
    }
  },
  mounted() {
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>

.main_profile_wrapper{
  background: url('../../../assets/new-theme/lci/background.png') no-repeat right top;
}

</style>
